<template>
  <div class="login">
    <h1 class="mb-4"><img src="/favicon.png" width="40px" class="mr-2" />Manage</h1>
    <form class="mb-5" @submit.prevent="loginWithEmail" style="min-width: 20rem;">
      <div class="form-group">
        <label for="email">Login</label>
        <input type="text" class="form-control" id="email" v-model="email" required />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" class="form-control" id="password" v-model="password" required />
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-primary" :disabled="loading">Login</button>
      </div>
    </form>
    <button type="button" class="btn btn-outline-secondary" @click="loginWithGoogle">
      <img src="@/assets/google-logo.png" width="24px" class="mr-2" />Login With Google
    </button>
  </div>
</template>

<script>
import { auth, fb } from '@/shared/firebase'

export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false
    }
  },
  created: function() {
    auth
      .getRedirectResult()
      .then(result => {
        if (result.credential) {
          this.$router.replace('/')
        }
      })
      .catch(function(error) {
        alert(error.message)
      })
  },
  methods: {
    loginWithEmail() {
      this.loading = true
      const email = this.email.includes('@') ? this.email : `${this.email}@login.mitemma.de`
      auth
        .signInWithEmailAndPassword(email, this.password)
        .then(() => {
          this.loading = false
          this.$router.replace('/')
        })
        .catch(err => {
          this.loading = false
          alert(err.message)
        })
    },
    loginWithGoogle() {
      var provider = new fb.auth.GoogleAuthProvider()
      fb.auth()
        .signInWithRedirect(provider)
        .then(() => {
          this.$router.replace('/')
        })
        .catch(err => {
          alert(err.message)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__inner {
    max-width: 30rem;
    flex-grow: 1;
    padding: 2rem;
    margin: 1rem;
    border: 1px #ddd solid;
  }
}
</style>
